<template>
  <div>
    <el-row   class="processManage" v-if="flagList">
      <el-row :gutter="4" class="elRow" >
        <el-col :span="3">
          <el-date-picker
            :clearable="false"
            style="width: 100%"
            v-model="queryObj.startTime"
            type="date"
            placeholder="请选择开始时间"
            size="small"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions0"
            @change="changeDate"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="0.5">
          <span style="line-height: 30px; color: #dcdfe6">~</span>
        </el-col>
        <el-col :span="3">
          <el-date-picker
            :clearable="false"
            style="width: 100%"
            v-model="queryObj.endTime"
            type="date"
            placeholder="请选择结束时间"
            size="small"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions1"
            @change="changeDate"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="3" >
          <el-select
            size="small"
            v-model="queryObj.selectType"
            placeholder="请选择"
          >
            <el-option
              v-for="item in ProcessType"
              :key="item.id"
              :label="$t(`Process.MyApplication.ProcessType.${item.label}`)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select
            style="width: 100%"
            v-model="queryObj.status"
            placeholder="请选择审批状态"
            size="small"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="$t(`Process.MyApplication.options.${item.label}`)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col style="position: relative; " :span="3.5">
          <el-input
            class="el-input-style"
            style="width: 240px"
            v-model.trim="dataArray.InputText"
            size="small"
            :placeholder="`请输入${placeholder}`"
            @keyup.enter.native="search('1')"
          ></el-input>
          <el-select
            class="el-select-style"
            style="width: 100px; margin-left: 2px"
            size="small"
            placeholder="设备名称"
            v-model="dataArray.deviceName"
            @change="searchTypeChange"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <!-- <el-col :span="3">
          <el-select style="width: 100%" v-model="queryObj.name" placeholder="名称" size="small" @change="changeName">
            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.label">
            </el-option>
          </el-select>
        </el-col> -->
        <!-- <el-col :span="3">
          <el-input :placeholder="`请输入${placeholder}`" size="small" v-model="searchKeywords" @keyup.enter.native="search"></el-input>
        </el-col> -->
        <el-col :span="3">
          <el-button type="primary" size="small" @click="search('1')">{{
            $t("public.Inquire")
          }}</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="4" class="elRow">
        <el-col>
          <el-button
            size="small"
            :disabled="!hasSeleted"
            :type="backdataInfo"
            @click="approveList"
            >{{ $t("Process.MyApproval.ApprovaBtn") }}</el-button
          >
        </el-col>
      </el-row>
      <el-row :gutter="4" class="elRow" style="position: relative">
        <el-table
          :cell-style="{ padding: '3px 0px' }"
          :header-row-style="{ height: '30px' }"
          :default-sort="{ prop: 'ask.CREATE_TIME', order: 'descending' }"
          ref="tableData"
          :data="tableData"
          lazy
          size="mini"
          stripe
          tooltip-effect="dark"
          class="tableClass"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @sort-change="sort"
        >
          <el-table-column
            header-align="center"
            align="center"
            type="selection"
            :selectable="selectEnable"
            width="60"
          >
          </el-table-column>
          <el-table-column
            width="150"
            prop="ask_num"
            sortable="custom"
            :label="$t('Process.MyApplication.ApplicationNo')"
            :show-overflow-tooltip="true"
          >
              <template v-slot="{ row }">
                {{ row.askNum }}
              </template>
          </el-table-column>
          <el-table-column
            width="180"
            prop="ASK_NAME"
            sortable="custom"
            :label="$t('Process.MyApplication.Name')"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span
                @click="backDetail(row)"
                style="color: #d38a08; cursor: pointer"
                >{{ row.askName }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            width="150"
            prop="ASK_TYPE"
            sortable="custom"
            :label="$t('Process.MyApplication.ProcessTypes')"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <template v-if="row.askType == 1">
                {{ $t("Process.MyApplication.ProcessType.应用上架") }}
              </template>
              <template v-if="row.askType == 2">
                {{ $t("Process.MyApplication.ProcessType.应用下架") }}
              </template>
              <template v-if="row.askType == 3">
                {{ $t("Process.MyApplication.ProcessType.设备注册") }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            width="150"
            prop="loginname"
            sortable="custom"
            :label="$t('Process.MyApproval.Applicant')"
            :show-overflow-tooltip="true"
          >
              <template v-slot="{ row }">
                {{ row.apply }}
              </template> 
          </el-table-column>
          <el-table-column
            width="150"
            prop="groupfullname"
            sortable="custom"
            :label="$t('public.Section')"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span v-if="row.departMent">{{
                  row.departMent
                }}</span>
              <!-- <span v-if="row.departMent">
                {{ row.departMent.substr(groupFullNameShowByIndex) }}
              </span> -->
            </template>
          </el-table-column>
          <el-table-column
            prop="STATUS"
            sortable="custom"
            :label="$t('Process.MyApplication.ApprovalStatus')"
            :show-overflow-tooltip="true"
            width="150"
          >
            <template v-slot="{ row }">
              <el-link
                v-if="row.approveStatus == 0 || row.approveStatus == null"
                @click="statusDetail(row)"
                type="primary"
                style="color: #d38a08"
              >
                {{ $t("Process.MyApplication.options.待审批") }}
              </el-link>
              <el-link
                v-if="row.approveStatus == 1"
                @click="statusDetail(row)"
                type="primary"
                style="color: #d38a08"
              >
                {{ $t("Process.MyApproval.options.已审批") }}
              </el-link>
              <el-link
                v-if="row.approveStatus == 2"
                @click="statusDetail(row)"
                type="primary"
                style="color: #d38a08"
              >
                {{ $t("Process.MyApproval.options.已审批") }}
              </el-link>
              <el-link
                v-if="row.approveStatus == 3"
                @click="statusDetail(row)"
                type="primary"
                style="color: #d38a08"
              >
                {{ $t("Process.MyApplication.options.已废除") }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
            width="150"
            prop="ask.CREATE_TIME"
            sortable="custom"
            :label="$t('Process.MyApplication.ApplicationTime')"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              {{ row.createTime | dateformat }}
            </template>
          </el-table-column>
          <el-table-column
            prop="UPDATE_TIME DESC,CREATE_TIME"
            width="150"
            sortable="custom"
            :label="$t('Process.MyApplication.ApprovalTime')"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <template
                v-if="row.approveStatus == 0 || row.approveStatus == null"
              >
              </template>
              <template v-else-if="row.updateTime">
                {{ row.updateTime | dateformat }}
              </template>
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template v-slot="{ row }">
              <el-link
                type="primary"
                v-if="row.approveStatus == 0 || row.approveStatus == null"
                @click="handleEdit(row)"
                style="color: #d38a08; cursor: pointer"
                >{{ $t("Process.MyApproval.ApprovaBtn") }}</el-link
              >
              <el-link
                type="primary"
                v-if="row.approveStatus == 1 || row.approveStatus == 2"
                @click="handleEdits(row.id, row)"
                style="color: #d38a08; cursor: pointer"
                >{{ $t("public.Details") }}</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <pagination
          :page="currentPage"
          :limit="pageSize"
          :total="total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        ></pagination>
      </el-row>
    </el-row>
    <!-- 名称详情 -->
    <Eldialog
      @handleClose="handleClose"
      :num="'320px'"
      :title="title"
      :dialogVisible="dialogVisible"
      :flagbtn="false"
    >
      <detail-list :dictData="dataFrom" v-if="!clickNameFlag" />
      <deviceRegister v-if="clickNameFlag" :dictData="registerFrom" />
      <!-- <el-button style="float: right; margin:10px 0 0 0;" type="primary" @click="dialogVisible = false" size="mini">关闭</el-button> -->
    </Eldialog>
    <!-- 状态详情 -->
    <Eldialog
      :width="'1000px'"
      @handleClose="handleClose"
      :title="$t('Process.MyApplication.ApprovalStatus')"
      :dialogVisible="statusVisible"
      :flagbtn="false"
    >
      <approvalStatus :dictData="statusArr" />
      <el-button
        style="float: right; margin: 10px 0 0 0"
        type="primary"
        @click="statusVisible = false"
        size="mini"
        >{{ $t("Process.MyApplication.EndBtn") }}</el-button
      >
    </Eldialog>
    <!-- 操作详情 -->
    <Eldialog
      :width="'550px'"
      @handleClose="handleClose"
      :title="$t('Process.MyApproval.ApprovaBtn')"
      :dialogVisible="detailVisible"
      :flagbtn="false"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item
          :label="$t('Process.MyApproval.ApproveOpinion')"
          prop="approveStatus"
        >
          <el-radio-group v-model="approveStatus" @change="setRadio">
            <el-radio label="1">{{
              $t("Process.MyApproval.approveStatus.同意")
            }}</el-radio>
            <el-radio label="2">{{
              $t("Process.MyApproval.approveStatus.拒绝")
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :label="$t('Process.MyApproval.ApprovaDetail')"
          prop="desc"
        >
          <el-input
            type="textarea"
            :rows="6"
            v-model="form.desc"
            clearable
            size="small"
            maxlength="300"
            style="width: 90%"
          ></el-input>
        </el-form-item>
        <el-form-item style="margin-top: 20px">
          <el-button type="primary" size="mini" class="save" @click="affirm">{{
            $t("public.Verify")
          }}</el-button>
          <el-button
            style="margin-left: 10px"
            type="primary"
            @click="handleClose"
            size="mini"
            >{{ $t("public.Cancel") }}</el-button
          >
        </el-form-item>
      </el-form>
    </Eldialog>
    <!-- 查看详情 -->
    <Eldialog
      :width="'550px'"
      @handleClose="handleClose"
      :title="$t('public.Details')"
      :dialogVisible="detailCheck"
      :flagbtn="false"
    >
      <el-form
        :model="showDetailData"
        ref="showDetailData"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item
          :label="$t('Process.MyApproval.ApproveOpinion')"
          prop="approveStatus"
        >
          <template>
            <p
              v-if="showDetailData.approveStatus == '1'"
              style="font-size: 12px"
            >
              同意
            </p>
            <p
              v-if="showDetailData.approveStatus == '2'"
              style="font-size: 12px"
            >
              拒绝
            </p>
          </template>
        </el-form-item>
        <el-form-item
          :label="$t('Process.MyApproval.ApprovaDetail')"
          prop="desc"
        >
          <el-input
            type="textarea"
            :rows="6"
            v-model="form.desc"
            clearable
            disabled
            size="small"
            maxlength="300"
            style="width: 90%"
          ></el-input>
        </el-form-item>
        <el-form-item style="margin-top: 20px">
          <el-button type="primary" @click="handleClose" size="mini">{{
            $t("Process.MyApplication.EndBtn")
          }}</el-button>
        </el-form-item>
      </el-form>
    </Eldialog>
  </div>
</template>
<script>
import approvalStatus from "./components/approvalStatus";
import detailList from "./components/detail.vue";
import Eldialog from "@/components/elDialog";
import deviceRegister from "./components/deviceRegister.vue"; //设备注册申请详情
import pagination from "@/components/pagination/page.vue";
export default {
  components: {
    pagination,
    Eldialog,
    approvalStatus,
    deviceRegister,
    detailList,
  },

  data() {
    return {
      clickNameFlag: true, //名称详情弹框默认展示设备注册内容
      backdataInfo: "info",
      Successdialog: false, //控制弹出
      Sencond: 5, //设置初始倒计时
      isDisabled: false,
      time: null,
      seccess: "",
      showDetailData: {},
      approveStatus: "1",
      id: [],
      contentId: [],
      radio: "1",
      // 日期转变
      pickerOptions1: {},
      pickerOptions0: {},
      // 全选弹框
      isCheckShow: false,
      // 全选框双向绑定
      checked: false,
      flagList: true, //新增编辑
      applyFlag: false, //选择应用
      dialogVisible: false, //详情弹框
      statusVisible: false, //状态弹框
      detailVisible: false, //操作详情弹框
      detailCheck: false, //查看详情弹框
      title: "审批详情",
      content: "新增页面",
      queryObj: {
        startTime: "",
        endTime: "",
        selectType: "",
        name: "申请编号",
        status: "",
      },
      dataArray: {
        deviceName: "0",
        InputText: "",
      },
      ProcessType: [
        {
          value: "",
          label: "流程类型",
        },
        {
          value: "1",
          label: "应用上架",
        },
        {
          value: "2",
          label: "应用下架",
        },
        {
          value: "3",
          label: "设备注册",
        },
      ],
      options: [
        {
          value: "",
          label: "审批状态",
        },
        {
          value: "0",
          label: "待审批",
        },
        {
          value: "1",
          label: "已审批",
        },
        {
          value: "3",
          label: "已废除",
        },
      ],
      typeOptions: [
        {
          value: "0",
          label: "申请编号",
        },
        {
          value: "1",
          label: "名称",
        },
      ],
      statusAppro: 1,
      multipleSelection: [],
      tableData: [],
      ruleForm: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      dataFrom: {},
      registerFrom: {}, //设备注册申请详情表单
      rules: {
        approveStatus: [{ required: true, trigger: "blur" }],
        desc: [],
      },
      form: {
        desc: "",
      }, //审批弹框
      currentPage: 1,
      pageSize: 10,
      total: 0,
      placeholder: "申请编号",
      searchKeywords: "", //查询关键字
      applyDetail: {}, //申请详情
      statusArr: [],
      // flag: 1,//判断审批、详情、编辑
      orderColume: "ask.CREATE_TIME", //排序字段
      orderRule: "DESC", //排序规则ASC、DESC
      groupFullNameShowByIndex: "",
    };
  },
  computed: {
    hasSeleted() {
      return this.multipleSelection && this.multipleSelection.length;
    },
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    console.log(this.groupFullNameShowByIndex);
    this.getDataList(); //列表初始化
    // this.setRadio();
  },

  methods: {
    searchTypeChange(val) {
      let dataName = this.typeOptions.find((item) => item.value == val);
      this.placeholder = dataName.label;
    },
    selectEnable(row, rowIndex) {
      if (row.approveStatus == 1 || row.approveStatus == 2|| row.approveStatus == 3) {
        return false;
      } else {
        return true;
      }
    },
    approveList() {
      this.form.desc = "";
      this.approveStatus = "1";
      this.statusAppro = 2; //全选
      this.detailVisible = true;
    },
    // 详情弹框限制特殊字符
    keyUp(e) {
      e.target.value = e.target.value
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ""
        )
        .replace(/\s/g, "");
    },
    setRadio() {
      if (this.approveStatus == "2") {
        this.rules = {
          approveStatus: [{ required: true, trigger: "change" }],
          desc: [
            {
              required: true,
              message: this.$t("Process.MyApproval.rules.ApproveDetail"),
              trigger: "blur",
            },
          ],
        };
        this.$refs.form.resetFields();
      } else {
        this.rules = {
          approveStatus: [{ required: true, trigger: "change" }],
          desc: [],
        };
        this.$refs.form.resetFields();
      }
    },
    async search(val) {
      if (val == 1) {
        this.currentPage = 1;
      }
      var param = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        startTime: this.queryObj.startTime,
        endTime: this.queryObj.endTime,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        approveStatus: this.queryObj.status,
        askType: this.queryObj.selectType,
        askNum:
          this.dataArray.deviceName == 0
            ? this.dataArray.InputText
            : this.queryObj.askNum,
        askName:
          this.dataArray.deviceName == 1
            ? this.dataArray.InputText
            : this.queryObj.askName,
      };
      const res = await this.$axios.post(
        "/httpServe/processApproval/getApprovalDataInfo",
        param,
        true
      );
      // console.log(res.data);
      this.tableData = res.data.content;
      this.total = res.data.total;
      if (this.queryObj.name == "名称" && this.searchKeywords != "") {
        this.tableData = res.data.content.filter((i, k) => {
          return i.askName.includes(this.searchKeywords);
        });
        this.total = this.tableData.length;
      }
      if (this.queryObj.name == "申请编号" && this.searchKeywords != "") {
        this.tableData = res.data.content.filter((i, k) => {
          return i.askNum.includes(this.searchKeywords);
        });
        this.total = this.tableData.length;
      }
    },
    // 更改输入框名称
    changeName(item) {
      this.placeholder = item;
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page;
      this.pageSize = limit;
      // 调用查询方法
      this.search();
    },
    // 日期
    changeDate() {
      let start = new Date(this.queryObj.startTime).getTime();
      let end = new Date(this.queryObj.endTime).getTime();
      this.pickerOptions0 = {
        disabledDate: (time) => {
          if (end != "") {
            return time.getTime() > Date.now() || time.getTime() > end;
          }
          return time.getTime() > Date.now();
        },
      };
      this.pickerOptions1 = {
        disabledDate: (time) => {
          return (
            time.getTime() < start - 24 * 60 * 60 * 1000 ||
            time.getTime() > Date.now()
          );
        },
      };
    },
    // 列表
    async getDataList() {
      //一个月前日期
      var getAnyMonth = function (symbol, n) {
        var symbol = symbol || "-";
        var nowDate = new Date();
        nowDate.setMonth(nowDate.getMonth() + n);
        var year = nowDate.getFullYear();
        var month = nowDate.getMonth() + 1;
        var day = nowDate.getDate();
        day = day < 10 ? "0" + day : day;
        month = month < 10 ? "0" + month : month;
        return year + symbol + month + symbol + day;
      }; //当前日期
      var getDate = function () {
        var date = new Date();
        var year = date.getFullYear();
        var mon = date.getMonth() + 1;
        var day = date.getDate();
        return (
          year +
          "-" +
          (mon < 10 ? "0" + mon : mon) +
          "-" +
          (day < 10 ? "0" + day : day)
        );
      };
      this.queryObj.startTime = getAnyMonth("-", -1);
      this.queryObj.endTime = getDate();
      this.changeDate();
      var param = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        startTime: this.queryObj.startTime,
        endTime: this.queryObj.endTime,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        approveStatus: this.queryObj.status,
        askType: this.queryObj.selectType,
        askName: "",
        askNum: "",
      };
      const res = await this.$axios.post(
        "/httpServe/processApproval/getApprovalDataInfo",
        param,
        true
      );
      // console.log(res.data);
      this.tableData = res.data.content;
      // this.tableData.forEach((item) => {
      //   console.log(item.departMent.indexOf("•") !== -1);
      //   if (item.departMent.indexOf("•") !== -1) {
      //     item.departMent = item.departMent.split("•")[1];
      //   }
      // });
      this.total = res.data.total;
      if (this.queryObj.name == "名称" && this.searchKeywords != "") {
        this.tableData = res.data.content.filter((i, k) => {
          return i.askName.includes(this.searchKeywords);
        });
        this.total = this.tableData.length;
      }
      if (this.queryObj.name == "申请编号" && this.searchKeywords != "") {
        this.tableData = res.data.content.filter((i, k) => {
          return i.askNum.includes(this.searchKeywords);
        });
        this.total = this.tableData.length;
      }
    },
    backDetail(val) {
      let arr = [];
      arr = JSON.parse(val.content);
      this.title = this.$t("Process.MyApplication.titleDetail");

      if (val.askType == 3) {
        this.registerFrom = { ...val };
        this.registerFrom.content = JSON.parse(this.registerFrom.content);
        this.registerFrom.content.groupName =
          this.registerFrom.content.groupName.indexOf("•") !== -1
            ? this.registerFrom.content.groupName.split("•")[1]
            : this.registerFrom.content.groupName;
        this.clickNameFlag = true;
        console.log(this.registerFrom, "this.registerFrom");
        this.clickNameFlag = true;
        console.log(this.registerFrom, "this.registerFrom");
      } else {
        this.clickNameFlag = false;
        if (arr.appName !== undefined) {
          this.dataFrom = {
            ids: val.ids,
            workflowId: val.workflowId,
            askName: val.askName,
            askType: val.askType,
            os: arr.os,
            appName: arr.appName,
            appVersion: arr.appVersion,
            appPackage: arr.appPackage,
            inspectProve: arr.inspectProve,
            // inspectProve:arr.inspectProve.filePath
            inspectReport: arr.inspectReport,
            // inspectReport: arr.inspectReport.filePath,
            useRange: arr.useRange,
            functionInt: arr.functionInt,
            groupName:
              arr.groupName.indexOf("•") !== -1
                ? arr.groupName.split("•")[1]
                : arr.groupName,
            phone: arr.phone,
          };
        } else {
          this.dataFrom = { ...val };
          this.dataFrom.content.groupName =
            this.dataFrom.content.groupName.indexOf("•") !== -1
              ? this.dataFrom.content.groupName.split("•")[1]
              : this.dataFrom.content.groupName;
          console.log(this.dataFrom, "this.dataFrom");
        }
      }
      this.dialogVisible = true;
    },
    handleClose() {
      console.log(this.$refs,'this.$refs');
      // this.$refs.form.resetFields();
      this.dialogVisible = false;
      this.statusVisible = false;
      this.applyFlag = false;
      this.detailVisible = false;
      this.detailCheck = false;
      this.$refs.showDetailData.resetFields();
    },
    // 状态
    async statusDetail(row) {
      this.statusline(row.id);
      // console.log(row.id);
      this.statusVisible = true;
    },
    // 审批状态查询接口
    async statusline(data) {
      // let arr = [];
      // arr.push(data);
      var param = {
        ids: [data],
      };
      const res = await this.$axios.post(
        "/httpServe/processApproval/approveStatus",
        param,
        true
      );
      // console.log(res, "审批状态接口");
      this.statusArr = res.data;
      this.statusArr.forEach((item) => {
        if (item.opeator.indexOf("•") !== -1) {
          item.opeator = item.opeator.split("•")[1];
        }
      });
    },
    //审批
    async handleEdit(val) {
      this.form.desc = "";
      this.approveStatus = "1";
      this.statusAppro = 1; //单选审批状态
      this.contentId = val.contentId;
      this.id = val.id;
      this.$set(this.form, "desc", "");
      // /processApproval/saveApproval
      this.detailVisible = true;
    },

    // 详情
    async handleEdits(id, row) {
      this.id = id;
      this.detailIDs(id);
      this.detailCheck = true;
      this.showDetailData = row;
    },
    async detailIDs(id) {
      let params = {
        ids: [id],
      };
      const res = await this.$axios.post(
        "/httpServe/processApproval/selectApproval",
        params,
        true
      );
      // console.log(res, "详情");
      this.approveStatus = res.data.approveStatus;
      this.form.desc = res.data.approveMemo;
    },
    // 保存 审批
    async affirm() {
      console.log(this.statusAppro, "11111111111111111111111111111111");
      if (this.statusAppro == 2) {
        let idList = [];
        this.multipleSelection.forEach((item) => {
          if (item.approveStatus == 0 || item.approveStatus == null) {
            idList.push(item.id);
          }
        });
        var params = {
          ids: idList,
          approveStatus: this.approveStatus,
          approveMemo: this.form.desc,
        };
        console.log(params);
        if (this.approveStatus == 2 && this.form.desc == "") {
          return false;
        }
        const res = await this.$axios.post(
          "/httpServe/processApproval/saveApproval",
          params,
          true
        );
        if (res.data == 2) {
          this.$message.error(res.message);
        }
        if (res.data == 1) {
          this.$message.success(res.message);
        }
        if (res.code == 101) {
          this.$message.error(res.message);
        }
        // this.statusArr = res.data;
        this.detailVisible = false;
        this.getDataList();
      } else if (this.statusAppro == 1) {
        var params = {
          contentId: this.contentId,
          ids: [this.id],
          approveStatus: this.approveStatus,
          approveMemo: this.form.desc,
        };
        if (this.approveStatus == 2 && this.form.desc == "") {
          return false;
        }
        const res = await this.$axios.post(
          "/httpServe/processApproval/saveApproval",
          params,
          true
        );
        if (res.data == 2) {
          this.$message.error(res.message);
        }
        if (res.data == 1) {
          this.$message.success(res.message);
        }
        if (res.code == 101) {
          this.$message.error(res.message);
        }
        // this.statusArr = res.data;
        this.detailVisible = false;
        this.getDataList();
      }
    },
    //返回
    goBack() {
      this.flagList = true;
    },
    //新建
    createFrom() {
      this.content = "新增页面";
      this.flagList = false;
    },
    // 新建提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          return false;
        }
      });
    },
    handleAvatarSuccess1() {},
    handleAvatarSuccess2() {},
    toApplyFlag() {
      this.applyFlag = true;
    },
    checkChange(selection, row) {
      console.log(selection);
    },
    // 排序
    sort(column) {
      this.orderColume = column.prop;
      // console.log(column.order, column.prop);
      if (column.order === "ascending") {
        this.orderRule = "ASC";
        if (column.prop == "UPDATE_TIME DESC,CREATE_TIME") {
          this.orderColume = "UPDATE_TIME ASC,CREATE_TIME";
        }
      } else if (column.order === "descending") {
        this.orderRule = "DESC";
      }

      this.getDataList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (this.multipleSelection.length > 0) {
        this.backdataInfo = "primary";
      } else {
        this.backdataInfo = "info";
      }
    },
    //当前页全选
    curSelection() {
      this.$refs.tableData.toggleAllSelection();
    },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true;
          });
          this.$refs.tableData.toggleRowSelection(row);
        });
      } else {
        this.$refs.tableData.clearSelection();
      }
      this.isCheckShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.processManage {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 10px;
}
.el-select-style {
  position: absolute;
  left: 3px;
  top: 0;
  border: none;
  border-left: none;
  border-right: 1px solid #c0c4cc !important;
  margin-top: 5px;
  margin-right: 2px;
}

::v-deep .el-select-style {
  .el-input__inner {
    border: 0;
    height: 23px;
    line-height: 23px;
  }
  .el-input--small .el-input__icon {
    line-height: 23px;
  }
}
::v-deep .el-range-editor.is-active,
.el-range-editor.is-active:hover,
.el-select .el-input.is-focus .el-input__inner {
  border-color: #c0c4cc;
}
::v-deep .el-input-style .el-select .el-input.is-focus .el-input__inner {
  border-color: #fff !important;
}
::v-deep .el-select-style .el-input--suffix .el-input__inner {
  border-color: #fff !important;
}
::v-deep .el-input-style {
  .el-input__inner {
    padding-left: 110px;
  }
}
.el-link.el-link--primary {
  color: rgb(241, 155, 50);
}

.el-form-item {
  margin-bottom: 0px !important;
}
</style>